
import { StripeKey } from '@/config/auth';
import { License, PaymentIntentResponseModel } from '@/models';
import { defineComponent, ref } from 'vue';
import { useStripe, StripeElement } from 'vue-use-stripe';

export default defineComponent({
	components: { StripeElement },
	props: {
		intent: {
			type: Object as () => PaymentIntentResponseModel,
			default: null
		},
		license: {
			type: Object as () => License,
			default: null
		}
	},
	setup(props: { intent: PaymentIntentResponseModel }) {
		const event = ref(null);

		const {
			stripe,
			stripeElements,
			elements: [cardElement]
		} = useStripe({
			key: StripeKey,
			elementsOptions: {
				clientSecret: props.intent.stripeClientSecret
			},
			elements: [
				{
					type: 'payment',
					options: {
						style: {
							base: {
								fontFamily: 'sans-serif'
							}
						}
					}
				}
			]
		});

		const handleChange = evt => console.log(evt);

		const registerCard = async () => {
			const result = await stripe.value.confirmPayment({
				elements: stripeElements.value,
				confirmParams: {
					// Return URL where the customer should be redirected after the PaymentIntent is confirmed.
					return_url: window.location.href
				}
			});

			if (result.error) {
				console.log(result);
			}
		};

		return {
			event,
			cardElement,
			handleChange,
			registerCard
		};
	}
});
