
import { User } from '@/models';
import { defineComponent, reactive } from 'vue';

export default defineComponent({
	emits: ['detailsSet'],
	props: {
		user: {
			type: Object as () => User,
			default: null
		}
	},
	setup(props, { emit }) {
		console.log(props.user);

		const userModel = reactive<User>({
			...props.user
		});

		const submit = () => {
			emit('detailsSet', userModel);
		};

		return {
			userModel,
			submit
		};
	}
});
