
import { License, PaymentIntentResponseModel, User } from '@/models';
import router, { FacilitatorRoute } from '@/router';
import { ApiService, AuthService, BackgroundService } from '@/services';
import { defineComponent, onMounted, reactive, ref } from 'vue';
import DetailsScreen from './screens/Details.vue';
import LicenseScreen from './screens/License.vue';
import BillingScreen from './screens/Billing.vue';
import { SubscribeBackground } from '@/config/backgrounds';

type Screens = 'idle' | 'license' | 'details' | 'billing';

export default defineComponent({
	components: { LicenseScreen, DetailsScreen, BillingScreen },
	setup() {
		const user = reactive<User>({} as User);
		const screen = ref<Screens>('idle');
		const license = ref<License>(null);
		const intent = ref<PaymentIntentResponseModel>(null);

		const tryActivate = async paymentIntent => {
			await ApiService.post('/auth/activate', { paymentIntent });
			router.push({ name: FacilitatorRoute });
		};

		onMounted(async () => {
			BackgroundService.set(SubscribeBackground);
			const loggedIn = await AuthService.isLoggedIn();
			if (!loggedIn) {
				AuthService.login();
				return;
			}

			Object.assign(user, await ApiService.getUser());
			const query = router.currentRoute.value.query;

			if (query?.payment_intent) {
				await tryActivate(query?.payment_intent);
			}

			if (user.license) {
				router.push({ name: FacilitatorRoute });
			}

			const profile = await AuthService.getUserProfile();

			user.firstName = user.firstName ?? profile.given_name;
			user.lastName = user.lastName ?? profile.family_name;
			user.email = profile.email;

			screen.value = 'license';
		});

		const setLicense = (selectedLicense: License) => {
			license.value = selectedLicense;
			if (user.dateRegistered) {
				initBilling();
			} else {
				screen.value = 'details';
			}
		};

		const setDetails = async (details: User) => {
			Object.assign(user, details);
			screen.value = 'idle';
			try {
				await ApiService.post('/auth', user);
				initBilling();
			} catch {
				screen.value = 'details';
			}
		};

		const initBilling = async () => {
			intent.value = await ApiService.post<PaymentIntentResponseModel>(
				`/auth/pay/${license.value.id}`
			);
			screen.value = 'billing';
		};

		return { screen, user, intent, license, setLicense, setDetails };
	}
});
