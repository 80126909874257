
import { defineComponent, onMounted, ref } from 'vue';
import { License } from '@/models';
import { ApiService } from '@/services';

export default defineComponent({
	emits: ['licenseSelected'],
	setup(_, { emit }) {
		const licenses = ref<License[]>([]);
		const singleLicense = ref<License>();

		onMounted(async () => {
			licenses.value = await ApiService.get<License[]>('/licenses');
		});

		const selectLicense = (license: License) =>
			emit('licenseSelected', license);

		return { licenses, singleLicense, selectLicense };
	}
});
